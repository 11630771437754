<template>
  <div class="a-date">
    <label v-if="label" :for="id"
      >{{ $t(label) }} <span v-if="optional" class="optional">{{ $t('optional') }}</span></label
    >
    <span class="a-date__input">
      <datepicker
        v-model="date"
        :input-class="{ 'is-invalid': invalid }"
        :id="id"
        :bootstrap-styling="true"
        :disabled="isDisabled"
        :language="getLang"
        :format="'yyyy-MM-dd'"
        :placeholder="$t('dateFormatShort')"
        :typeable="true"
        :use-utc="true"
        :append-to-body="true"
      ></datepicker>
      <span @click="trigger" class="a-date__input__icon"></span>
    </span>
    <div v-show="invalid" class="invalid-feedback">{{ $t(invalidText) }}</div>
    <div class="invalid-feedback html5-validation" v-html="validationFeedback"/>
  </div>
</template>

<script>
import Datepicker from '@sum.cumo/vue-datepicker'
import en from '@sum.cumo/vue-datepicker/dist/locale/translations/en'
import fr from '@sum.cumo/vue-datepicker/dist/locale/translations/fr'
import { Dates } from '@/_helpers'
import i18n from '@/i18n'

export default {
  components: { Datepicker },
  data() {
    return {
      en,
      fr
    }
  },
  props: {
    invalidText: {},
    label: {},
    value: {},
    submitted: {},
    id: {
      default() {
        return `input-${this._uid}`
      }
    },
    optional: {
      type: Boolean,
      default() { return false}
    },
    validationFeedback: {
      type: String,
      default() {
        return ''
      }
    }
  },
  computed: {
    invalid() {
      return this.invalidText && this.submitted && !this.value
    },
    isDisabled() {
      return this.disabled === 'disabled'
    },
    getLang() {
      return this[i18n.locale]
    },
    date: {
      get() {
        return Dates.initDate(this.value)
      },
      set(val) {
        this.$emit('input', Dates.getFormFormatDate(val))
        this.$el.dispatchEvent(new CustomEvent('change', { bubbles: true }))
      }
    }
  },
  methods: {
    input() {
      return this.$el.querySelector('input')
    },
    trigger() {
      this.input().focus()
      this.input().click()
    }
  },
  watch: {
    validationFeedback(message) {
      this.input().setCustomValidity(message)
    }
  },
  mounted() {
    this.input().setCustomValidity(this.validationFeedback)
  }
}
</script>

<style lang="scss">
@import '~@sum.cumo/vue-datepicker/src/styles/style.scss';
@import '../../utilities/config';
.a-date {
  &__input {
    position: relative;
    display: block;
    input {
      padding-right: 42px;
      &::placeholder {
        color: gray('gray-150');
        font-weight: 300;
      }
    }
    &__icon {
      cursor: pointer;
      width: 22px;
      height: 22px;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translate(0, -50%);
      background-image: url(../../assets/calendar_dark_green.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  .form-control[readonly] {
    background-color: gray('white');
  }
}

.vdp-datepicker {
  &__calendar {
    .cell:not(.blank):not(.disabled).day:hover,
    .cell:not(.blank):not(.disabled).month:hover,
    .cell:not(.blank):not(.disabled).year:hover {
      border: 1px solid darken(theme-color('primary'), 0.3);
      background-color: theme-color('faded-green');
      &.selected {
        background-color: theme-color('primary');
        font-weight: 500; //font-weight: 400;;
        color: gray('white');
        &:hover {
          background-color: darken(theme-color('primary'), 0.3);
        }
      }
    }
    .cell {
      border-radius: 40px;
      &.selected {
        background-color: theme-color('primary');
        font-weight: 500; //font-weight: 400;;
        color: gray('white');
      }
    }
  }
}

.was-validated .form-control:invalid {
  background-image: none;
}

.invalid-feedback.html5-validation {
  display: none;
}

.was-validated .invalid-feedback.html5-validation {
  display: block;
}
</style>
